<template>
  <div class="login-page">
    <div class="wrapper wrapper-full-page">
      <div class="full-page login-page section-image">
        <div class="content">
          <div class="container">
            <div class="col-lg-4 col-md-6 ml-auto mr-auto">
              <h2 class="text-white mt-3">
                Page Not Found
              </h2>
              <small class="text-white"
                >Oops! It seems that this page does not exist. <br />You can
                navigate back
                <router-link to="/">to main page</router-link>
              </small>
            </div>
          </div>
        </div>
        <div
          class="full-page-background"
          style="background-image: url(static/img/background/jan-sendereks.jpg) "
        ></div>
      </div>
    </div>
  </div>
</template>
<script>
export default {};
</script>
<style></style>
